import React from 'react';
import { createStyles, makeStyles, SvgIcon, Theme } from '@material-ui/core';
import { mainTheme } from '../Theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardIcon: {
            width: '1.5em',
            height: '1.5em',
            cursor: 'pointer',
            color: mainTheme.BrandColors.TemplatePrimary,
        }
    })
);

interface Props {
    iconKey: string;
    url: string;
    size?: 'inherit' | 'default' | 'small' | 'large';
}

export function getFileType(file: string | null) {
    const fileName = file;
    const fileExtension = fileName?.slice(fileName.lastIndexOf('.') + 1).toLowerCase();

    switch (fileExtension) {
        case 'pdf':
            return 'pdf';
        case 'docx':
        case 'xls':
            return 'doc';
        default:
            return 'url';
    }
}

const linkTo = (url: string) => () => {
    window.open(url, '_blank');
};

const GeneralMaterialIcon: React.FC<Props> = ({ iconKey, url, size = 'large' }) => {

    const classes = useStyles();

    let IconComponent = null;

    switch (iconKey) {
        case 'pdf':
            IconComponent = require('@material-ui/icons/PictureAsPdf').default;
            break;
        case 'url':
            IconComponent = require('@material-ui/icons/InsertLinkOutlined').default;
            break;
        case 'doc':
            IconComponent = require('@material-ui/icons/Description').default;
            break;
        default:
            IconComponent = require('@material-ui/icons/Description').default;
            break;
    }

    return (
        <SvgIcon onClick={linkTo(url)} className={classes.cardIcon} fontSize={size}>
            <IconComponent />
        </SvgIcon>
    );
};

export default GeneralMaterialIcon;
