import { makeStyles, Theme, createStyles, Grid, Paper } from '@material-ui/core';
import React from 'react';
import { RouterProps } from '../App';
import { mainTheme } from '../Theme';
import { LearnMoreBanner } from '../learnMore/LearnMoreBanner';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { RvLoader } from '../components/Loader';
import GeneralMaterialIcon, { getFileType } from './GeneralMaterialIcon';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexFlow: 'column',
            flex: 1,
        },
        content: {
            padding: '0 15px 10px 15px',
        },
        paperContainer: {
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: '30px 15px',
            minHeight: '278.938px'
            // height: '100%',
        },
        cardIcon: {
            width: '1.5em',
            height: '1.5em',
            color: mainTheme.BrandColors.TemplatePrimary,
        },
        cardHeading: {
            margin: '15px 0',
            fontWeight: 600,
            color: mainTheme.BrandColors.TemplatePrimary,
        },
        cardIconLink: {
            width: '1.5em',
            height: '1.5em',
            cursor: 'pointer',
            color: mainTheme.BrandColors.TemplatePrimary,
        },
        cardHeadingLink: {
            margin: '15px 0',
            fontWeight: 600,
            cursor: 'pointer',
            color: mainTheme.BrandColors.TemplatePrimary,
            '&:hover': {
                textDecoration: 'underline',
                textUnderlinePosition: 'under'
            }
        },
        lists: {
            listStyleType: 'none',
            padding: 0,
            margin: 0,
            '& li > a': {
                display: 'block',
                marginBottom: 10
            }
        },
        duration: {
            fontStyle: 'italic',
            fontWeight: 600,
            textAlign: 'center',
        },
        description: {
            marginBottom: 0,
        },
        innerLists: {
            listStyleType: 'none',
            padding: 0,
            marginBottom: 10
        },
        documentLinkWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flexFlow: 'column',
            textAlign: 'center',
            paddingTop: 10
        }
    })
);

interface GeneralMaterialPageProps extends RouterProps {
    isConvey: boolean;
}

export default function GeneralMaterialPage(props: GeneralMaterialPageProps) {

    const classes = useStyles();

    const onClickBack = () => {
        props.history.goBack();
    };

    const linkTo = (url: string) => () => {
        window.open(url, '_blank');
    };

    // tslint:disable-next-line:no-any
    const generalMaterialsQuery = useQuery<GeneralMaterialsData>(GENERAL_MATERIALS_QUERY, {
        variables: {
            isConvey: true,
        },
        fetchPolicy: 'cache-and-network',
    });

    if (generalMaterialsQuery.loading) {
        return (
            <div className={classes.root}>
                <RvLoader size="small" />
            </div>
        );
    }

    const { generalMaterials } = generalMaterialsQuery.data!.generalMaterialList;

    return (
        <div className={classes.root}>
            <LearnMoreBanner
                title={'Knowledge Bank'}
                onClickBack={onClickBack}
            />
            <div className={classes.content}>
                <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} sm={12} md={12}>
                        <Grid container={true} spacing={3}>
                            {generalMaterials.map((material) => (
                                <Grid item={true} xs={12} sm={12} md={3}>
                                    <Paper
                                        elevation={3}
                                        className={classes.paperContainer}
                                    >
                                        <GeneralMaterialIcon
                                            iconKey={getFileType(material.fileName)}       
                                            url={material.url}                       
                                        />
                                        <h3
                                            className={classes.cardHeadingLink}
                                            onClick={linkTo(material.url)}
                                        >
                                            {material.label}
                                        </h3>
                                        <p className={classes.description}>{material.description}</p>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export interface GeneralMaterialsData {
    generalMaterialList: GeneralMaterialList;
}

export interface GeneralMaterialList {
    generalMaterials: GeneralMaterial[];
}

export interface GeneralMaterial {
    id: number;
    type: number;
    sequence: number;
    label: string;
    fileName: null | string;
    url: string;
    isConvey: boolean;
    description: string;
    matterCategory: null | string;
    matterTypeId: number;
    matterType: null | string;
}

const GENERAL_MATERIALS_QUERY = gql`
query GeneralMaterials ($isConvey: Boolean){
    generalMaterialList(isConvey: $isConvey) {
      generalMaterials {
        id,
        type,
        sequence,
        label,
        fileName,
        url,
        isConvey,
        description,
        matterCategory,
        matterCategory,
        matterTypeId,
        matterType
      }
    }
  }
`;
