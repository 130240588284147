export const mainTheme = {
    Font: {
        Main: 'Nunito'
    },
    FontSize: {
        PX: {
            size1: '14px',
            size2: '15px',
            size3: '16px',
            size4: '18px',
            size5: '20px',
            size6: '22px',
            size7: '24px',
            size8: '36px',
            size9: '48px',
            size10: '50px',
            size11: '32px',
        },
        EM: {
            size1: '1.75em',
            size2: '4em'
        },
        REM: {
            size1: '1rem',
            size2: '1.5rem',
            size3: '2.5rem',
            size4: '5rem',
        }
    },

    TemplateColor: {
        Primary: '#5173FF',
        Secondary: '#70D389',
    },

    TextColor: {
        Primary: '#23253B',
        Secondary: '#3C3C3C',
        Tertiary: '#A8AABA',
    },

    CommonColor: {
        Dark: '#000000',
        LightGrey1: '#F7F8FA',
        LightGrey2: '#EEEEEE',
        LightGrey3: 'rgba(35,37,59,0.75)',
        LightGrey4: 'rgb(244, 244, 244)',
        LightBlue1: '#D9DFF7',
        LightBlue2: '#F5F6FF',
        LightBlue3: '#077bba',
        LightBlue4: '#rgb(81, 115, 255, 0.5)',
        BackgroundWhite: '#FFFFFF',
        BackgroundGrey1: 'rgba(81,115,255,0.41)',
        BackgroundGrey2: 'rgba(81,115,255,0.33)',
        BackgroundGrey3: 'rgba(81,115,255,0.25)',
        BackgroundGrey4: 'rgba(81,115,255,0.09)',
        BackgroundGrey5: 'rgba(81,115,255,0.03)',
    },

    BrandColors: {
        'TemplatePrimary': '#5173FF !important',
        'TemplateSecondary': '#70D389 !important',
        'TextPrimary': '#23253B !important',
        'TextSecondary': '#3C3C3C !important',
        'TextTertiary': '#A8AABA !important',
    },
    NeutralColors: {
        'Dark': '#000000',
        'LightGrey1': '#F7F8FA',
        'LightGrey2': '#EEEEEE !important',
        'LightGrey3': 'rgba(35,37,59,0.75) !important',
        'LightGrey4': 'rgb(244, 244, 244)',
        'LightBlue1': '#D9DFF7 !important',
        'LightBlue2': '#F5F6FF !important',
        'LightBlue3': '#077bba !important',
        'LightBlue4': '#rgb(81, 115, 255, 0.5) !important',
        'BackgroundWhite': '#FFFFFF !important',
        'BackgroundGrey1': 'rgba(81,115,255,0.41) !important',
        'BackgroundGrey2': 'rgba(81,115,255,0.33) !important',
        'BackgroundGrey3': 'rgba(81,115,255,0.25) !important',
        'BackgroundGrey4': 'rgba(81,115,255,0.09) !important',
        'BackgroundGrey5': 'rgba(81,115,255,0.03) !important',
    },
    UtilityColors: {
        'Negative': '#D50000 !important',
        'Positive': '#70D389 !important',
        'Neutral': '#E18713 !important'
    },
    LineHeight: {
        Tiny: '12px',
        Small: '19px',
        Regular1: '22px',
        Regular2: '25px',
        Medium: '30px',
        Large: '40px',
        Large2: '44px',
        ExtraLarge: '49px'
    },
    Opacity: {
        cat1: 'initial',
        cat2: '0.51',
        cat3: '0.52',
        cat4: '1',
    },    

    FontWeight: {
        Normal: 'normal',
        Bold: 'bold',
        Bolder: 'bolder',
        Lighter: 'lighter',
        Initial: 'initial',
        Inherit: 'inherit',
        OneHundred: 100,
        TowHundred: 200,
        ThreeHundred: 300,
        FourHundred: 400,
        FiveHundred: 500,
        SixHundred: 600,
        SevenHundred: 700,
        EightHundred: 800,
        NineHundred: 900
    }
};

export const secondaryTheme = {
    main: { 
        color: '#ffffff !important', // white text color
        backgroundColor: 'red !important', // red background
    }, 
    inverted: { 
        color: 'red !important', // red text color
        backgroundColor: '#ffffff !important', // white background
    },
};